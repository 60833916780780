export const useSearch = createSharedComposable(() => {
	const isOpen = ref(false);

	return {
		isOpen,
		open() {
			isOpen.value = true;
		},
		close() {
			isOpen.value = false;
		}
	};
});
